import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NpfHome from './npf/Home';
import NpfProjects from './npf/OurProjects';
import ContactUs from './npf/ContactUs';
import OurBlog from './npf/OurBlog';
import { IntlProvider } from "react-intl";
import enMessages from "./locales/en.json";
import viMessages from './locales/vi.json'
import Header from './npf/Header';
import Footer from './npf/Footer';
import AboutUs from "./npf/AboutUs";
import News from './npf/News';
import 'antd/dist/reset.css';
import TopPanel from "./npf/TopPanel";
import Privacy from "./npf/Privacy";
import VideoPage from "./npf/VideoPage";

const localeMessages = {
  en: enMessages,
  vi: viMessages,
};

function App() {

  const [lang, setLang] = useState('en');

  const toggleLang = () => {
    lang === 'en' ? setLang('vi') : setLang('en');
  };

  return (
    <div className="App">
      <IntlProvider locale={lang} messages={localeMessages[lang]}>
        <Router>
          <TopPanel toggleLang={toggleLang} lang={lang} />
          <Header toggleLang={toggleLang} lang={lang}/>
          <Routes>
            <Route path='/' exact element={<NpfHome />}/>
            <Route path='/about' element={<AboutUs />}/>
            <Route path='/livelihood' exact element={<NpfProjects />}/>
            <Route path='/inner-change' exact element={<NpfProjects />}/>
            <Route path='/systems-change' exact element={<NpfProjects />}/>
            <Route path='/contact' exact element={<ContactUs />}/>
            <Route path='/blog' exact element={<OurBlog />}/>
            <Route path='/news' exact element={<News />}/>
            <Route path='/privacy-policy' exact element={<Privacy />}/>
            <Route path='/ripplesofkindness' exact element={<VideoPage />}/>
          </Routes>
          <Footer toggleLang={toggleLang} />
        </Router>
      </IntlProvider>
    </div>
  );
}

export default App;
