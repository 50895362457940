import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
import {useIntl} from "react-intl";

const imgs = importAllImg(require.context('../img/npf', false, /\.(png|jpe?g|svg)$/));


function AboutUs() {

  const pics = ['al-soot-vWqfut0kI7k-unsplash.png', 'jimmy-chang-LP5WXkrnxX0-unsplash.png', 'allan-bueno-FtIC-0JbqU8-unsplash.png'];
  const [index, setIndex] = useState(0);
  const ref = useRef(null);

  const { formatMessage, locale } = useIntl();
  const fm = id => formatMessage({id});

  useEffect(() => {
    const header = document.querySelector('#header');
    if (header) {
      // header.style.background = 'linear-gradient(to right, transparent 50%, white 50%)';
      header.style.background ='rgba(255, 255, 255, 0.5)';
      header.style.zIndex = 10;
    }
    return () => {
      const header = document.querySelector('#header');
      if (header) {
        header.style.background = '';
        header.style.zIndex = 2;
      }

    }
  }, [locale]);

  const toggleImg = () => {
    const offTop = ref.current.scrollTop;
    if (offTop >= 0 && offTop < 188) {
      setIndex(0);
    } else if (offTop >= 188 && offTop < 376) {
      setIndex(1)
    } else {
      setIndex(2);
    }
  };
  return (
    <Container>
      <div className='top-panel'>
        <img className='left fit-mobile-img' src={imgs['jimmy-chang-LP5WXkrnxX0-unsplash.png']} />
        <div className='right'>
          <div className='right-panel'>
            <div className='panel-title margin-top80'>{fm('ABOUT US')}</div>
            <div className='panel-content'>
              <div>
                {
                  fm('The Nguyễn-Phương Family believes in paying it forward by working with social impact organizations and changemakers. We collaborate with partners around the world working to provide education, strengthen communities and support systems changing solutions. We aim to leave the world a little better than we found it. We do our work through private philanthropy with historical roots in Vietnam and a global vision. Our work is made possible through the ongoing engagement of the Nguyễn-Phương Family and our partners.')
                }
              </div>
            </div>
          </div>
          <img className='fit-desktop-img' src={imgs['jimmy-chang-LP5WXkrnxX0-unsplash.png']} />
        </div>
      </div>

      <div className='middle-panel'>
        <div className='our-approach'>
          <div>{fm('BEHIND THE LOGO')}</div>
          <div className='behind-text'>
            {fm('Rice terraces (Ruộng bậc thang) are a symbol of the masterpiece of nature blended with the creativity, patience and collaboration of humans. Rice is a key source of life for all people - regardless of their background.')}
            <p></p>
            {fm('The font used in our logo is Ven, a typeface created by Dat Trong Do. This font was inspired by the Ao Dai and Vietnamese conical hat - to represent the beauty, gracefulness and mysteriousness of Vietnam.')}
          </div>
        </div>
        <div className='middle-img-panel'>
          <img src={imgs['mountain-6576362_1280.png']} />
          <div>
            <img src={imgs['Group 2962 (1).svg']} />
          </div>
        </div>
      </div>

      <div className='mobile-middle-panel'>
        <div className='behind-title'>{fm('BEHIND THE LOGO')}</div>
        <img src={imgs['Group 3000.svg']} />
        <div className='behind-text'>
          {fm('Rice terraces (Ruộng bậc thang) are a symbol of the masterpiece of nature blended with the creativity, patience and collaboration of humans. Rice is a key source of life for all people - regardless of their background.')}
          <p></p>
          {fm('The font used in our logo is Ven, a typeface created by Dat Trong Do. This font was inspired by the Ao Dai and Vietnamese conical hat - to represent the beauty, gracefulness and mysteriousness of Vietnam.')}
        </div>
      </div>

      <div className='core-container'>

        <div className='focus-title'>{fm('core values')}</div>

        <div className='core-content'>
          <div>{fm('ONENESS/INCLUSION')}</div>
          <div>{fm('To awaken to the truth that we are One')}</div>
        </div>

        <div className='core-content'>
          <div>{fm('TRUTH')}</div>
          <div>{fm('To change our lifestyle accordingly and live in truth')}</div>
        </div>

        <div className='core-content'>
          <div>{fm('EMPOWERMENT')}</div>
          <div>{fm('To help those who so wish to become agents of positive change')}</div>
        </div>

        <div className='core-content'>
          <div>{fm('INNOVATION')}</div>
          <div>{fm('To gather the right people, organizations and resources, and integrate innovation into this ecosystem')}</div>
        </div>

        <div className='core-content'>
          <div>{fm('IMPACT')}</div>
          <div>{fm('To create a multiplier effect that will result in a magnified impact')}</div>
        </div>
      </div>

      {/*<div className='behind-logo'>*/}
      {/*  <img src={imgs['Group 2868 (1).svg']} />*/}
      {/*  <img src={imgs['NGUYỄN-PHƯƠNG FAMILY (1).svg']} />*/}
      {/*  <div className='behind-title'>*/}
      {/*    {fm('BEHIND THE LOGO')}*/}
      {/*  </div>*/}
      {/*  <div className='behind-text'>*/}
      {/*    {fm('Rice terraces (Ruộng bậc thang) are a symbol of the masterpiece of nature blended with the creativity, patience and collaboration of humans. Rice is a key source of life for all people - regardless of their background.')}*/}
      {/*    <p></p>*/}
      {/*    {fm('The font used in our logo is Ven, a typeface created by Dat Trong Do. This font was inspired by the Ao Dai and Vietnamese conical hat - to represent the beauty, gracefulness and mysteriousness of Vietnam.')}*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className='top-panel'>*/}
      {/*  <img className='left fit-mobile-img' src={imgs['hoach-le-dinh-PeRt3uMmjYM-unsplash 1.png']} />*/}
      {/*  <div className='right'>*/}
      {/*    <div className='right-panel'>*/}
      {/*      <div className='bottom-item'>*/}
      {/*        <div className='panel-title'>{fm('FOLLOW US')}</div>*/}
      {/*        <div className='icons'>*/}
      {/*          <img src={imgs['Group 2892 (1).svg']} onClick={() => window.open('https://www.facebook.com/NguyenPhuongFamily')}/>*/}
      {/*          <img src={imgs['Group 2890 (1).svg']} onClick={() => window.open('https://medium.com/@familynguyenphuong')} />*/}
      {/*          <img src={imgs['Group 2891 (1).svg']} onClick={() => window.open('https://www.linkedin.com/company/77767320/')}/>*/}
      {/*          <a href="mailto:hello@nguyenphuongfamily.org">*/}
      {/*            <img src={imgs['Group 2893 (2).svg']} alt="Email Link" />*/}
      {/*          </a>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className='bottom-item'>*/}
      {/*        <div className='panel-title'>{fm('CONTACT US')}</div>*/}
      {/*        <div className='email-panel'>*/}
      {/*          <div>Email:</div>*/}
      {/*          <div><a href='mailto:hello@nguyenphuongfamily.org'>hello@nguyenphuongfamily.org</a></div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

    </Container>
  );
}

const Container = styled.div`
  
  .mobile-middle-panel {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
    
    background: #354402;
    padding: 30px 5%;
    
    .behind-title {
      font-family: 'Bebas Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 43px;
      color: #FFFFFF;
      margin-bottom: 40px;
      background-image: url("${imgs['Group 2884.svg']}");
      background-size: contain;
      height: 83px;
      display: flex;
      align-items: center;
      //background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 991px) {
        height: 42px;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 20px;
        justify-content: center;
        background-position: center;
      }
    }
    
    .behind-text {
      font-family: 'Open Sans';
      font-style: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #FFFFFF;
      margin-top: 15px;
      padding-bottom: 20px;
      
      & > span {
        font-weight: 700;
      }

    }
  }
  
  .core-content {
    margin-bottom: 30px;
    @media (max-width: 991px) {
      margin-bottom: 15px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    & > div:nth-child(1) {
      font-family: 'Bebas Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      color: #000000;
      text-transform: uppercase;
      margin-bottom: 5px;
      @media (max-width: 991px) {
        font-size: 16px;
      }
    }

    & > div:nth-child(2) {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }

  }

  .focus-title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    background-image: url("${imgs['Group 2884.svg']}");
    background-size: contain;
    height: 83px;
    display: flex;
    align-items: center;
    //justify-content: center;
    //background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 22px;
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 19px;
      height: 42px;
      margin-bottom: 30px;
      justify-content: center;
      background-position: center;
    }
  }
  
  .core-container {
    background: #F3F7E6;
    padding: 50px 5%;
    @media (max-width: 991px) {
      padding: 30px 5%;
    }
  }
  
  .middle-img-panel {
    position: relative;
    & > img {
      width: 120%;
      margin-left: -20%;
      height: 720px;
    }
    
    & > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .our-approach {
    background: #354402;
    height: 720px;
    width: 720px;
    display: flex;
    //align-items: center;
    justify-content: center;
    flex-flow: column;
    padding: 50px;
    //padding-left: 80px;
    padding-top: 0;
    position: relative;
    z-index: 2;

    border-radius: 0px 40px 40px 0px;


    @media (max-width: 991px) {
      height: 420px;
    }

    & > div:nth-child(1) {
      font-family: 'Bebas Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 43px;
      color: #FFFFFF;
      margin-bottom: 40px;
      background-image: url("${imgs['Group 2884.svg']}");
      background-size: contain;
      height: 83px;
      display: flex;
      align-items: center;
      //background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 991px) {
        height: 42px;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 20px;
      }

    }

    & > div:nth-child(2) {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      color: #FFFFFF;
      //text-align: center;

      font-size: 24px;

      color: #FFFFFF;

      & > span {
        font-weight: bold;
      }





      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 22px;
        width: 70%;
      }
    }
  }
  
  .middle-panel {
    display: flex;
    @media (max-width: 991px) {
      display: none;
    }
    & > div {
      flex: 0 0 50%;
    }
  }
  .behind-logo {
    background: #F3F7E6;
    padding: 93px 0 166px 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    @media (max-width: 991px) {
      padding: 20px 0;
    }
    & > img:nth-child(1) {
      margin-bottom: 16px;
      @media (max-width: 991px) {
        width: 55px;
        margin-bottom: 10px;
      }
    }
    & > img:nth-child(2) {
      margin-bottom: 60px;
      @media (max-width: 991px) {
        height: 26px;
        margin-bottom: 15px;
      }
    }
    .behind-title {
      color: #000;
      text-align: center;
      font-family: 'Bebas Neue';
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 12px;
      @media (max-width: 991px) {
        font-size: 24px;
        margin-bottom: 15px;
      }
    }
    .behind-text {
      max-width: 558px;
      color: #000;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 991px) {
        font-size: 14px;
        width: 85%;
      }
    }
  }
  .top-panel {
    display: flex;
    @media (max-width: 991px) {
      flex-flow: column;
    }
  }
  
  .fit-desktop-img {
    display: none;
    @media (max-width: 991px) {
      display: block;
      width: 100%;
    }
  }
  
  .fit-mobile-img {
    @media (max-width: 991px) {
      display: none !important;
    }
  }
  
  .left {
    flex: 0 0 50%;
    max-width: 50%;
    height: 100vh;
    object-fit: cover;
    z-index: 3;
    margin-right: -2%;
  }
  
  .right {
    flex: 0 0 50%;
    //height: 100vh;
    //overflow-y: auto;
    border-radius: 40px 0px 0px 40px;
    background: #FFFBF8;
    overflow: hidden;
    z-index: 4;
    //margin-left: -2%;
    margin-right: -2%;
    @media (max-width: 991px) {
      margin-left: 0;
      border-radius: 0;
    }
  }
  
  .right-panel {
    padding: 80px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background: #FFFBF8;
    border-radius: 40px 0px 0px 40px;
    height: 100%;
    margin-left: 2%;

    @media (max-width: 991px) {
      padding: 40px;
    }
  }
  
  .panel-content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
    }
    & > div:nth-child(1) {
      margin-bottom: 30px;
    }
  }
  
  .bottom-item {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .email-panel {
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    & > div:nth-child(1) {
      font-weight: 300;
      margin-bottom: 5px;
    }
    & > div:nth-child(2) {
      font-weight: 400;
      & > a {
        color: #000000;
        text-decoration: none;
      }
    }
  }
  
  .icons {
    margin-bottom: 180px;
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
    & > img, & > a {
      cursor: pointer;
      @media (max-width: 991px) {
        height: 42px;
        & > img {
          height: 42px;
        }
      }
    }
  }
  
  .icons > img:nth-child(3) {
    margin-right: 30px;
    @media (max-width: 991px) {
      margin-right: 15px;
    }
  }

  .icons > img:nth-child(2) {
    margin: 0 30px;
    @media (max-width: 991px) {
      margin: 0 15px;
    }
  }
  
  .margin-top80 {
    margin-top: 80px;
    @media (max-width: 991px) {
      margin-top: 0;
    }
  }

  .panel-title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    background-image: url("${imgs['Group 2885 (1).svg']}");
    background-size: contain;
    height: 83px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 47px;
    @media (max-width: 991px) {
      height: 42px;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
  }
`;


export default AboutUs;
