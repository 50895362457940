import styled from "styled-components";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import importAllImg from "../helpers/import-all-imgs";

const imgs = importAllImg(require.context('../img/npf', false, /\.(png|jpe?g|svg)$/));


function VideoPage() {

  const { formatMessage, locale } = useIntl();

  const fm = id => formatMessage({id});

  const isMobile = window.innerWidth <= 991;

  return (
    <Container>
      <div className='top-panel'>
        <div className='top-title1'>{fm('Ripples of Kindness')} </div>
        <div className='top-title2'>{fm('The Power of Paying It Forward')}</div>
        <div className='video-panel'>
          <iframe width={isMobile ? 297: 1019} height={isMobile ? 157 : 538} src="https://www.youtube.com/embed/Hn_xETZc_rE?si=XbPxA0yscp0SE7cZ"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
      </div>

      <div className='reset-container'>
        <div className='focus-area'>
          <div className='focus-title'>
            {fm('About the film')}
          </div>

          {
            locale === 'vi' ?
              <div className='focus-content'>
                <span>Tóm tắt nội dung phim:</span> <br/>
                Khi chúng ta cho đi một cách vô vị lợi mà không mong mỏi sự đền đáp thì dù chỉ là một hành động tử tế nhỏ thôi cũng có thể khởi tạo những làn sóng thay đổi tích cực vượt xa sự kỳ vọng của chúng ta. Ripples of Kindness (tạm dịch: Làn sóng lan tỏa của sự tử tế) là một minh chứng đẹp cho tác động của tinh thần đáp đền tiếp nối (paying-it-forward) và sức mạnh của tinh thần thiện nguyện được dẫn dắt bởi lòng nhân ái.
                <div></div>
                Câu chuyện trong bộ phim bắt đầu từ khoảnh khắc một người đàn ông quyết định sẽ tạo ra một quỹ học bổng. Không ngờ rằng điều này đã khơi nguồn cho một chuỗi những hành động hào phóng đến mức trở thành một phong trào tại quê hương Việt Nam của ông.
                <div></div>
                Một trong những người nhận học bổng, vì được truyền cảm hứng sâu sắc từ cơ hội này, đã thành lập một tổ chức tạo tác động xã hội, Vietseeds Foundation. Mục tiêu của VietSeeds không dừng lại ở việc chỉ cung cấp học bổng hỗ trợ cho thanh niên Việt Nam, những chủ nhận tương lai của đất nước, mà còn thông qua Sự chuyển hóa từ bên trong (Inner Transformation) để họ trở thành những nhà kiến tạo thực sự đem đến những thay đổi tích cực cho cộng đồng.
                <div></div>
                Hiệu ứng của sự lan tỏa này tiếp tục mở rộng khi một thành viên khác trong đội ngũ sáng lập của Vietseeds Foundation, chịu ảnh hưởng sâu sắc từ sức mạnh của sự chánh niệm, đã cùng với các đối tác chung chí hướng sáng lập Heart and Mind Education. Sáng kiến này hướng đến việc trang bị cho các nhà giáo dục tại Việt Nam những công cụ để nuôi dưỡng sự phát triển trí tuệ cảm xúc, xã hội và đạo đức cho học sinh.
                <div></div>
                Những nỗ lực rời rạc không thể tạo nên những thành tựu to lớn. Hành trình dệt nên tấm thảm muôn màu của những thay đổi tích cực và thật sự bền vững này đòi hỏi sự đóng góp chủ động một cách đầy nhân văn của mỗi cá nhân trong cộng đồng. Gieo một hạt giống, thay đổi một cuộc đời và chuyển đổi một quốc gia.
                <div></div>
                <span>Bạn sẽ bắt đầu làn sóng lan tỏa sự tử tế của chính mình như thế nào?</span> <br/>
                <div>
                  <span>Chúng tôi xin đặc biệt gửi lời cảm ơn chân thành đến:</span> <br/>
                  <span>Văn Đinh Hồng Vũ,</span> <i>Đồng sáng lập, VietSeeds Foundation</i> <br/>
                  <span>Huyền Tôn Nữ Cát Tường,</span> <i>Đồng sáng lập, VietSeeds Foundation</i> <br/>
                  <span>Trần Thị Minh Trang,</span> <i>Đồng sáng lập, Heart & Mind Education</i> <br/>
                  <span>Nguyễn Đức Thùy Anh,</span> <i>Đồng sáng lập, VietSeeds Foundation và Heart & Mind Education</i> <br/>
                  <span>Đạo diễn - Tâm Bùi</span><br/>
                  <span>Nhà sản xuất - Chi Lai</span> <br/>
                </div>
              </div> :
              <div className='focus-content'>
                <span>Synopsis:</span> <br/>
                When we give selflessly without expectation, a single act of kindness can set off a ripple of positive changes beyond our wildest hopes. Ripples of Kindness is a beautiful story that demonstrates the impact of paying-it-forward and the powerful force of philanthropy guided by the spirit.
                <div></div>
                The film follows the story of one man’s decision to create a scholarship that would unknowingly spark a chain of generosity that creates a movement in his native Vietnam.
                <div></div>
                A scholarship recipient, inspired by the opportunity she received, establishes a social impact organisation - VietSeeds Foundation. VietSeeds not only provides Vietnamese youth with scholarships, but cultivates the next generation of leaders to become changemakers in their communities through Inner Transformation.
                <div></div>
                The ripple effect continues when one of the co-founder’s of VietSeeds, deeply influenced by the power of mindfulness, goes on to co-found Heart and Mind Education with other partners. This initiative aims to provide Vietnamese educators with the tools to foster emotional, social, and ethical intelligence in students.
                We cannot achieve much alone. To create something truly sustainable, we must each contribute our thread and trust in humanity to weave together a tapestry of positive change. <span>Grow a seed, change a life, and transform a nation.</span>
                <div></div>
                How will you start your <span>Ripple Effect of Kindness?</span>
                <div></div>
                <div>
                  <span>Our credit and special thanks to:</span> <br/>
                  <span>Van Dinh Hong Vu</span> <i>(Co-Founder, VietSeeds Foundation)</i> <br/>
                  <span>Huyen Ton Nu Cat Tuong</span> <i>(Co-Founder, VietSeeds Foundation)</i> <br/>
                  <span>Tran Thi Minh Trang</span> <i>(Co-Founder of Heart & Mind Education)</i> <br/>
                  <span>Nguyen Duc Thuy Anh</span> <i>(Co-Founder, VietSeeds Foundation and Heart & Mind Education)</i> <br/>
                  <span>Director - Tam Bui </span><br/>
                  <span>Producer - Chi Lai</span> <br/>
                </div>
              </div>
          }


        </div>

        <div className='focus-area'>
          <div className='focus-title'>
            {fm('About the organizations')}
          </div>

          <div className='focus-flex'>
            <div className='focus-flex-item'>
              <div className='focus-flex-item-img-panel'>
                <img className='mobile-focus-flex-item-img1' src={isMobile ? imgs['vietseeds 2.png'] :imgs['vietseeds 1.svg']} /></div>

              <div>
                {
                  locale === 'vi'?
                    'VietSeeds là một tổ chức phi lợi nhuận được thành lập nhằm mang đến sự tiếp cận bình đẳng trong giáo dục đại học cho tất cả học sinh Việt Nam có hoàn cảnh khó khăn, hỗ trợ họ đạt được mục tiêu xây dựng một đời sống trí tuệ phong phú và trở thành những nhà kiến tạo đóng góp tích cực cho xã hội. Kể từ khi thành lập, VietSeeds luôn duy trì các giá trị cốt lõi của mình - Chính trực, Tư duy cầu tiến, Trao quyền, Cộng đồng và Ảnh hưởng, qua đó đã thành công trong việc giúp đỡ hơn 800 sinh viên trên khắp 3 miền, với gần 400 "hạt giống đã đơm hoa", bằng cách trao khoảng 3000 học bổng trong suốt 13 năm qua. Hoạt động dựa trên tinh thần "Đáp đền tiếp nối" (Pay It Forward) - khẩu hiệu chính của tổ chức, VietSeeds hy vọng sẽ tiếp tục lan tỏa niềm tin rằng "Không ai có thể thành công một mình".':
                    'VietSeeds is a non-profit organization founded in order to provide college education opportunities to all Vietnamese students with disadvantaged backgrounds, supporting them in achieving their goals of a rich intellectual life and pursue to become a social change-maker. Since its establishment, VietSeeds has always been maintaining their core values - Integrity, Growth Mindset, Empowerment, Community and Impact, therefore succeeded in helping over 800 students across the 3 regions, with almost 400 "blossomed seeds", by giving approximately 3000 scholarships over the last 13 years. Functioning based on the spirit of "Pay It Forward" - the organisation\'s main motto, VietSeeds hopes to keep on spreading our belief, that "No one can succeed alone".'
                }

              </div>
              <div className='focus-flex-item-link-panel'>
                <img onClick={() => window.open('https://vietseeds.org/')} src={imgs['arrow-down-right-svgrepo-com 4.svg']} />
              </div>
            </div>

            <div className='focus-flex-item'>
              <div className='focus-flex-item-img-panel'>
                <img className='mobile-focus-flex-item-img2' src={isMobile ? imgs['HME_Logo-removebg-preview 2.png']:imgs['HME_Logo-removebg-preview 1.svg']} /></div>

              <div className='focus-flex-item-content'>
                {
                  locale === 'vi' ?
                    'HME là một doanh nghiệp xã hội được thành lập dựa trên niềm tin rằng mọi trẻ em đều xứng đáng được tiếp nhận một nền giáo dục nhằm phát triển toàn diện trí tuệ cảm xúc, xã hội, và đạo đức. Heart & Mind Education là đối tác chính thức của Center of Contemplative Science and Compassion-based Ethics để triển khai và hỗ trợ việc sử dụng các chương trình SEE Learning tại Việt Nam. ':
                    'HME is a social enterprise founded on the belief that every child deserves to receive a holistic education that seeks to cultivate emotional, social, ethical, and intellectual growth. Heart & Mind Education is the Primary Affiliate of the Center of Contemplative Science and Compassion-based Ethics to implement and support the use of SEE Learning programs in Vietnam.'
                }

                <div></div>
                {
                  locale === 'vi' ?
                    'Sứ mệnh: Mục tiêu của chúng tôi là phổ biến một hình thức giáo dục cả về tâm hồn và tư duy tại Việt Nam thông qua việc triển khai chương trình SEE Learning cho tất cả trẻ em, phụ huynh, và giáo viên, từ đó, xây dựng một cộng đồng vững mạnh nơi mọi người không ngừng nuôi dưỡng lòng nhân ái và sự trắc ẩn.':
                    'Mission: We aim to bring the education of the heart and mind to Vietnam with the implementation of SEE Learning for all the children, parents, teachers, building a strong community to continuously foster kindness and compassion for all.'
                }
              </div>
              <div className='focus-flex-item-link-panel'>
                <img onClick={() => window.open('https://heartandmind.edu.vn/')} src={imgs['arrow-down-right-svgrepo-com 4.svg']} />
              </div>
            </div>
          </div>
        </div>

        <div className='inspire-panel'>
          <div>
            <img onClick={() => window.open('https://inspiringasia.org/')} src={isMobile ? imgs['image 2255.png']:imgs['image 2255.svg']} />
          </div>
          {
            locale === 'vi' ?
              <div>
                Lấy cảm hứng từ châu Á, chúng tôi sẽ đi sâu vào tính nhân văn và những vấn đề thuộc về tâm hồn. Những câu chuyện đầy cảm hứng về tình yêu thương, sự bao dung, cần cù và những ước mơ của những người con Á châu được lật mở và ghi lại. Thông qua chủ đề "Inspring Asia” (tạm dich: Một Châu Á Truyền Cảm Hứng), chúng tôi sẽ tiếp tục lan tỏa tinh thần, giá trị và sức mạnh của người châu Á để biến châu Á trở thành một nơi tốt đẹp hơn cho tất cả mọi người. Với sự hỗ trợ của Li Foundation và Asia Philanthropy Circle, chúng tôi đã khởi xướng sáng kiến công ích nhằm tạo ra một nền tảng dành cho các phim tài liệu ngắn truyền cảm hứng về chủ đề "Inspiring Asia”. Chúng tôi mong rằng những câu chuyện phi thường về những con người châu Á bình dị, sẽ soi sáng và sưởi ấm cho cuộc sống của mỗi chúng ta.
              </div> :
              <div>
                With content based in Asia, we will delve deep into humanity and matters of the heart. Uncover inspiring stories that capture the love, inclusiveness, diligence and dreams of the Asians. Through the theme of “Inspiring Asia”, we will continue to promote the spirit, value and strength of Asians to make Asia a better place for all.
                <div></div>
                With the support of the Li Foundation and Asia Philanthropy Circle, we launched the public welfare initiative that aims to create a platform for influential short documentaries tied to the theme of “Inspiring Asia”. We aim to share extraordinary anecdotes of ordinary Asian people that can bring warmth and light to our lives.
              </div>
          }

        </div>
      </div>

    </Container>
  )
}

const Container = styled.div`
  
  .inspire-panel {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    margin-top: 20px;
    
    @media (max-width: 991px) {
      font-size: 11px;
    }
    
    & > div:first-child {
      margin-bottom: 10px;
      @media (max-width: 991px) {
        text-align: center;
      }
        
      img {
        cursor: pointer;
        @media (max-width: 991px) {
          height: 33px;
        }
      }
    }
    

    
    & > div:last-child > div {
      margin-bottom: 15px;
    }
  }
  
  .focus-flex-item-img-panel {
    height: 84px;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      height: auto;
      margin-bottom: 5px;
      
      .mobile-focus-flex-item-img1 {
        height: 25px;
      }
      .mobile-focus-flex-item-img2 {
        height: 40px;
      }
    }
  }
  
  .focus-flex-item-content {
    & > div {
      margin-bottom: 15px;
      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
    }
  }
  
  .focus-flex {
    display: flex;
    column-gap: 40px;
    & > div {
      flex: 1;
    }
    @media (max-width: 991px) {
      flex-flow: column;
      row-gap: 10px;
    }
  }
  
  .focus-flex-item-link-panel {
    text-align: right;
    
    & > img {
      cursor: pointer;
      @media (max-width: 991px) {
        width: 29px;
      }
    }
  }
  
  .focus-flex-item {
    background: #F2F2F7;
    border-radius: 14px;
    padding: 20px 15px;
    

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;

    @media (max-width: 991px) {
      padding: 15px 10px;
      font-size: 10px;
    }
  }
  
  .focus-content {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 18px;
    color: #000000;

    @media (max-width: 991px) {
      font-size: 11px;
    }
    
    span {
      font-weight: bold;
    }
    
    div {
      margin-bottom: 15px;
      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
        
    }


  }
  
  .reset-container {
    width: 85%;
    margin: 30px auto 30px auto;
  }

  .focus-title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    background-image: url("${imgs['Group 2884.svg']}");
    background-size: contain;
    height: 83px;
    display: flex;
    align-items: center;
    //justify-content: center;
    //background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 22px;
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 19px;
      height: 42px;
      margin-bottom: 10px;
      justify-content: center;
      background-position: center;
    }
  }
  
  .video-panel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .top-title2 {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    color: #00539C;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 16px;
    }

  }
  
  .top-title1 {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    //margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 32px;
    }

  }
  
  .top-panel {
    background-image: url("${imgs['linus-nylund-Q5QspluNZmM-unsplash 1.png']}");
    background-size: 100% 760px;
    height: 760px;
    padding: 30px 7.5%;
    @media (max-width: 991px) {
      background-image: url("${imgs['linus-nylund-Q5QspluNZmM-unsplash 2.png']}");
      background-size: 100% 283px;
      height: 283px;
      padding: 15px 5% 10px 5%;
    }
  }
  
  //width: 85%;
  margin: 100px auto 30px auto;
 

  @media (max-width: 991px) {
    margin: 20px auto;
    margin-top: 60px;
  }
  
`

export default VideoPage;
