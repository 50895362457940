import importAllImg from "../helpers/import-all-imgs";
import styled from "styled-components";
import React from "react";


const imgs = importAllImg(require.context('../img/npf', false, /\.(png|jpe?g|svg)$/));

function TopPanel({toggleLang, lang}) {

  return (
    <Container>
      <img className='desktop-img' src={imgs['Group 2945 (3).svg']} onClick={() => window.open('https://www.facebook.com/NguyenPhuongFamily')} />
      <img className='desktop-img' src={imgs['Group 2943 (2).svg']} onClick={() => window.open('https://www.linkedin.com/company/77767320/')} />
      <img className='desktop-img' src={imgs['Group 2942 (2).svg']} onClick={() => window.open('https://medium.com/@familynguyenphuong')} />
      <img onClick={toggleLang} src={lang === 'en' ? imgs['Group 2961 (1).svg']:  imgs['Group 2960 (1).svg']} />

    </Container>
  )
}

const Container = styled.div`
  background: #354402;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 7.5%;
  justify-content: flex-end;
  column-gap: 30px;

  @media (max-width: 991px) {
    height: 32px;
  }
  
  & > img {
    cursor: pointer;

    @media (max-width: 991px) {
      height: 20px;
      &.desktop-img {
        display: none;
      }
    }
  }
`;

export default TopPanel;
