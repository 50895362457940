import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
import { Popover, Drawer } from 'antd';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl';


const imgs = importAllImg(require.context('../img/npf', false, /\.(png|jpe?g|svg)$/));

const Container = styled.div`
  background-color: transparent;
  position: relative;
  
  
  & > section {
    //top: -10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    position: relative;
    margin-bottom: -30px;
    //padding: 60px 0 80px 0;
    position: fixed;

    z-index: 3;
    padding: 20px 7.5% 7px 7.5%;

    font-style: normal;
    line-height: 1.2;

    color: #354402;


    border-radius: 0px 0px 30px 30px;

    & > div {
      padding-bottom: 3px;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      //&:hover {
      //  color: #354402;;
      //}
      //&.selected {
      //  border-color: #354402;
      //  color: #354402;
      //}
    }

    .lang-panel {
      display: flex;
      align-items: center;
      & > img {
        margin-right: 10px;
      }
    }

  }
   

 
`;

const MobileContainer = styled.div`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 20px 20px;
  z-index: 3;
  position: relative;
  margin-bottom: -10px;
  background: white;
  position: fixed;
  width: 100%;

`;

const PopContent = styled.div`
  width: 250px;
  padding-top: 15px;
  padding-left: 20px;
  .for-img-span {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
  }
  & > div {
    cursor: pointer;
    font-weight: 400;
    font-size: 22px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #354402;
    margin-bottom: 18px;
    font-family: 'Bebas Neue';
    font-style: normal;
    line-height: normal;
  }
`;

const DContent = styled.div`

  .for-img-span {
    display: inline-flex;
    width: 32px;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 0 !important;
      width: 23px;
    }
  }

  .lang-panel {
    display: flex;
    align-items: center;
    & > img {
      margin-right: 10px;
    }
  }
  
  & > div {
    font-family: 'Bebas Neue';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    //color: #000000;
    color: #354402;
    margin-bottom: 32px;
    padding-bottom: 3px;
    border-bottom: 3px solid transparent;
    width: fit-content;
    &.selected {
      color: #354402;
      border-bottom-color: #354402;
    }
  }
  
  .projects {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sub-projects {
    margin-top: -20px;
    font-size: 16px;
    line-height: 19px;
    //padding-left: 20px;
    margin-bottom: 0;
    & > div {
      margin-bottom: 24px;
      
      & > img {
        height: 23px;
        margin-right: 10px;
      }
      
      & > span {
        margin-right: 10px;
      }
    }
  }
`;

function Header({toggleLang, lang}) {

  const navigate = useNavigate();
  const rrLocation = useLocation();
  const [selected, setSelected] = useState(null);
  const { formatMessage, locale } = useIntl();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const ref = useRef(null);

  const fm = id => formatMessage({id});
  const isMobile = window.innerWidth <= 991;

  useEffect(() => {
    const event = () => {
      const scrollTop = window.scrollY;
      const header = document.querySelector('#header');
      if (header) {
        if (scrollTop > 50) {
          header.style.background = 'rgba(255, 255, 255, 0.5)';
          header.style.top = 0;
        } else {
          header.style.top = '';
          header.style.background = '';
          // header.style.background = 'white';
          if (rrLocation.pathname != '/about') {
            // header.style.background = 'transparent';
          }
        }
      }
    };
    window.addEventListener('scroll', event)

    return () => {
      window.removeEventListener('scroll', event)
    }
  }, []);

  useEffect(() => {
    if (rrLocation.pathname === '/') {
      return setSelected(0);
    }
    if (['/livelihood', '/poverty', '/inner-change', '/systems-change'].includes(rrLocation.pathname)) {
      return setSelected(1);
    }
    if (rrLocation.pathname === '/blog') {
      return setSelected(2);
    }
    if (rrLocation.pathname === '/contact') {
      return setSelected(3);
    }
    if (rrLocation.pathname === '/about') {
      return setSelected(4);
    }
    if (rrLocation.pathname === '/news') {
      return setSelected(5);
    }
  }, [rrLocation]);

  useEffect(() => {
    const header = document.querySelector('#header');
    if (header) {
      if (lang === 'en') {
        header.setAttribute('style', 'font-family: "Bebas Neue" !important; font-size: 22px;');
      } else {
        header.setAttribute('style', 'font-family: "Be Vietnam Pro" !important; font-weight: bold; font-size: 18px');
      }
    }
  }, [lang]);


  const changeRouter = url => {
    navigate(url);
    setShow(false);
  };

  const changeDesktopRouter = url => {
    navigate(url);
    ref.current.close();
  };

  const content = <PopContent>
    {/*<div onClick={() => changeDesktopRouter('/livelihood')}>*/}
    {/*  {fm('LIVELIHOOD')}*/}
    {/*</div>*/}
    <div onClick={() => changeDesktopRouter('/livelihood')}>
      <img src={imgs['Hand Heart11.svg']} />
      {fm('LIVELIHOOD')}
    </div>
    <div onClick={() => changeDesktopRouter('/inner-change')}>
      <img src={imgs['Meditation11.svg']} />
      {fm('INNER CHANGE')}
    </div>
    <div onClick={() => changeDesktopRouter('/systems-change')}>
      <span className='for-img-span'>
        <img src={imgs['puzzle (2).svg']} />
      </span>
      {fm('SYSTEMS CHANGE')}
    </div>
    <div onClick={() => changeDesktopRouter('/ripplesofkindness')}>
      <span className='for-img-span'>
        <img src={imgs['Group 2970 (2).svg']} />
      </span>
      {fm('RIPPLES OF KINDNESS')}
    </div>
  </PopContent>;

  if (isMobile) {
    return (
        <MobileContainer id='header'>
          <img src={imgs['Group 2875.svg']} onClick={() => changeRouter('/')} />
          <img onClick={() => setShow(true)} src={imgs['Icon (Left).svg']} />
          <Drawer
            title={<img onClick={() => changeRouter('/')} style={{width: '85%'}} src={imgs['Group 2875.svg']} />}
            placement="right"
            closable={false}
            onClose={() => setShow(false)}
            open={show}
            width={256}
          >
            <DContent>
              {/*<div className='lang-panel' onClick={toggleLang}>*/}
              {/*  <img src={imgs['language.svg']}/>*/}
              {/*  {locale === 'en' ? 'EN' : 'VI'}*/}
              {/*</div>*/}
              <div onClick={() => changeRouter('/')}>{fm('Home')}</div>
              {/*<div><img src={imgs['language.svg']} onClick={toggleLang}/></div>*/}
              <div onClick={() => changeRouter('/about')} className={selected === 4 ? 'selected' : ''}>{fm('ABOUT US')}</div>
              {/*<div onClick={() => navigate('/npf-home')} className={selected === 0 ? 'selected' : ''}>{fm('ABOUT US')}</div>*/}
              <div className={selected === 1 ? 'selected projects' : 'projects'} onClick={() => setShow2(i => !i)}>
                {fm('OUR PROJECTS')}
                <img src={imgs['CaretDown.svg']}/>
              </div>
              <div className='sub-projects' style={{display: show2 ? 'block': 'none'}}>
                {/*<div onClick={() => changeRouter('/livelihood')}>{fm('LIVELIHOOD')}</div>*/}
                {/*/!*<div onClick={() => changeRouter('/poverty')}>{fm('POVERTY ALLEVIATION')}</div>*!/*/}
                {/*<div onClick={() => changeRouter('/inner-change')}>{fm('INNER CHANGE')}</div>*/}
                {/*<div onClick={() => changeRouter('/systems-change')}>{fm('SYSTEMS CHANGE')}</div>*/}
                <div onClick={() => changeRouter('/livelihood')}>
                  <img width={32} src={imgs['Hand Heart11.svg']} />
                  {fm('LIVELIHOOD')}
                </div>
                <div onClick={() => changeRouter('/inner-change')}>
                  <img width={32} src={imgs['Meditation11.svg']} />
                  {fm('INNER CHANGE')}
                </div>
                <div onClick={() => changeRouter('/systems-change')}>
                  <span className='for-img-span'>
                    <img src={imgs['puzzle (1).svg']} />

                  </span>
                  {fm('SYSTEMS CHANGE')}
                </div>
                <div onClick={() => changeRouter('/ripplesofkindness')}>
                  <span className='for-img-span'>
                    <img src={imgs['Group 2970 (2).svg']} />
                  </span>
                 {fm('RIPPLES OF KINDNESS')}
                </div>
              </div>
              <div className={selected === 2 ? 'selected' : ''} onClick={() => changeRouter('/blog')}>{fm('BLOG')}</div>
              <div className={selected === 5 ? 'selected' : ''} onClick={() => changeRouter('/news')}>{fm('NEWS')}</div>
              <div className={selected === 3 ? 'selected' : ''} onClick={() => changeRouter('/contact')}>{fm('CONTACT')}</div>
            </DContent>
          </Drawer>
        </MobileContainer>
    );
  };

  return (
    <Container>
      <section id='header'>
        <div onClick={() => navigate('/')}><img src={imgs['Group 2942.svg']} /></div>
        <div onClick={() => navigate('/')}>{fm('Home')}</div>
        <div onClick={() => navigate('/about')} className={selected === 4 ? 'selected' : ''}>{fm('ABOUT US')}</div>
        <Popover ref={ref} content={content} placement="bottom">
          <div className={selected === 1 ? 'selected' : ''}>
            {fm('OUR PROJECTS')}
            <img style={{marginLeft: 5}} src={imgs['CaretDown12.svg']} />
          </div>
        </Popover>
        <div className={selected === 2 ? 'selected' : ''} onClick={() => navigate('/blog')}>{fm('BLOG')}</div>
        <div className={selected === 5 ? 'selected' : ''} onClick={() => navigate('/news')}>{fm('NEWS')}</div>
        <div className={selected === 3 ? 'selected' : ''} onClick={() => navigate('/contact')}>{fm('CONTACT')}</div>
        {/*<div className='lang-panel' onClick={toggleLang}>*/}
        {/*  <img src={imgs['language.svg']}/>*/}
        {/*  {locale === 'en' ? 'EN' : 'VN'}*/}
        {/*</div>*/}
      </section>
    </Container>
  );
}


export default Header;
